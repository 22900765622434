.ck-toggleable-tags {
  margin-bottom: 24px;
  width: 100%;
}
.ck-toggleable-tags .label {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
  margin-bottom: 5px;
}
.ck-toggleable-tags .tags-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}
.ck-toggleable-tags .ck-tag {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background: #fcfcfc;
  height: 32px;
  padding: 5px 10px;
}

.ck-toggleable-tags .ck-tag span:not(.anticon) {
  color: var(--text-color);
  font-size: 14px;
  line-height: 22px;
  margin-left: 0;
}
.ck-toggleable-tags .ck-tag span.anticon {
  height: 14px;
}
.ck-toggleable-tags .ck-tag span.anticon svg {
  height: 14px;
  width: auto;
}

.ck-toggleable-tags .ck-tag.selected {
  background-color: var(--light-blue);
  border: none;
}
.ck-toggleable-tags .ck-tag.selected span.anticon {
  color: var(--primary-color);
}
