.ck-rate {
}
.ck-rate .rate-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 0px;
  /* height: 20px; */
}
.ck-rate p.rate-label,
.ck-rate p.rate-count {
  color: var(--text-color);
  font-size: 16px;
  line-height: 20px;
}
.ck-rate p.rate-label {
  font-weight: 600;
  margin-bottom: 5px !important;
}
.ck-rate p.rate-count {
  margin-bottom: 0px !important;
}
.ck-rate .ant-rate-star {
  color: var(--secondary-color);
}
.ck-rate .ant-rate-star.ant-rate-star-zero .ant-rate-star-first,
.ck-rate .ant-rate-star.ant-rate-star-zero .ant-rate-star-second,
.ck-rate .ant-rate-star.ant-rate-star-half .ant-rate-star-second {
  color: var(--black-20) !important;
}
.ck-rate .ant-rate-star:not(:last-child) {
  margin-right: 6px;
}
