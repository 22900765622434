.ck-vehicle-type-selection .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-vehicle-type-selection .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-vehicle-type-selection .ant-form-item-row .ant-col.ant-form-item-label {
  margin-bottom: 5px;
}
.ck-vehicle-type-selection label {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
}
.ck-vehicle-type-selection .ant-form-item-control-input-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.ck-vehicle-type-selection .ant-form-item-control-input-content .ant-btn {
  width: 100px;
  height: 56px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid var(--black-20);
  padding: 8px;
  transition: all 0.2s ease-in-out;
}
.ck-vehicle-type-selection.size-full .ant-form-item-control-input-content .ant-btn {
  width: 100%;
}
.ck-vehicle-type-selection .ant-form-item-control-input-content .ant-btn span {
  color: var(--black-20);
}
.ck-vehicle-type-selection .ant-form-item-control-input-content .ant-btn.selected {
  background: var(--primary-color);
}
.ck-vehicle-type-selection .ant-form-item-control-input-content .ant-btn.selected span {
  color: #fff;
}
