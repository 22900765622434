.ck-select-time input {
  width: 100%;
}
.ck-select-time .ant-select-selection-item {
  color: var(--gray);
  font-size: 16px;
  line-height: 20px;
}
.ck-select-time .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-select-time .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-select-time .ant-form-item-explain div {
  color: #666;
  font-size: 12px;
  line-height: 15px;
  color: var(--gray-450);
}
.ck-select-time
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  margin-right: 0;
  color: var(--text-color);
}
.ck-select-time .ant-select-arrow .anticon > svg {
  color: var(--primary-color);
  height: 18px;
  width: auto;
}
.ck-select-time .ant-select-single.ant-select-show-arrow .ant-select-selection-item, 
.ck-select-time .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
}

.ck-select-time-popover .ant-select-item.ant-select-item-option {
  color: var(--gray, #404040);
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  width: auto;
  min-width: 75px;
  margin: 5px auto;
  height: 24px;
  min-height: 24px;
  padding: 0;
  border-radius: 4px;
}
.ck-select-time-popover .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
  color: #fff !important;
}

/* ==========================================================================
  Transform options
  ========================================================================== */
.ck-select-time-popover.lowercase .ant-select-item-option-content {
  text-transform: lowercase;
}
.ck-select-time-popover.uppercase .ant-select-item-option-content {
  text-transform: uppercase;
}
.ck-select-time-popover.capitalize .ant-select-item-option-content {
  text-transform: capitalize;
}

/* ==========================================================================
  Default
  ========================================================================== */
.ck-select-time.default {
}
.ck-select-time.default .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-select-time.default .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-select-time.default label,
.ck-select-time.default .ant-form-item-extra {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 26px;
  height: 26px;
}
.ck-select-time.default .ant-select-selector {
  border: 0;
  border-bottom: 1px solid var(--text-color);
  border-radius: 0px !important;
}
.ck-select-time.default .ant-select-arrow {
  color: var(--text-color);
  right: 0;
}
.ck-select-time.default .ant-select-selection-placeholder {
  text-align: left !important;
  color: var(--gray-300) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 4px 0;
}
.ck-select-time.default .ant-select-selector {
  padding-left: 0;
  padding-right: 0;
}

.ck-select-time-popover.default {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #888;
  background: var(--white);
  top: 30px !important;
  font-size: 16px;
  font-weight: 600;
}
/* .ck-select-time-popover.default .ant-select-item {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
} */

/* ==========================================================================
  Rounded
  ========================================================================== */
.ck-select-time.rounded {
  color: #fff;
}
.ck-select-time.rounded label,
.ck-select-time.rounded .ant-form-item-extra {
  color: #fff;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 26px;
  height: 26px;
}
.ck-select-time.rounded input {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: var(--white);
  text-align: left;
}

.ck-select-time.rounded .ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 8px;
  overflow: hidden;
}
.ck-select-time.rounded .ant-input-clear-icon,
.ck-select-time.rounded .ant-input-clear-icon svg {
  height: 18px;
  width: auto;
}
.ck-select-time.rounded .ant-select-selector {
  height: 44px;
}

.ck-select-time.rounded
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ck-select-time.rounded
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  line-height: 44px;
}

.ck-select-time-popover.rounded {
  border-radius: 8px;
  border: 1px solid #888;
  background: var(--white);
  font-size: 16px;
  font-weight: 600;
}
.ck-select-time.rounded .ant-form-item-explain div,
.ck-select-time.rounded
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  color: #fff;
}
