
.ck-message.ant-message-notice {
  width: 100% !important;
  margin: 0 auto !important;
  padding: 10px 0;
  transition: width 0.5s ease;
}
/* Workshop widths */
.ws-app.authenticated .ck-message.ant-message-notice {
  width: calc(100% - 61px) !important;
  margin-left: 61px !important;
}
.ws-app.authenticated .expanded-menu .ck-message.ant-message-notice {
  width: calc(100% - 300px) !important;
  margin-left: 300px !important;
}
/* End of Workshop widths */
.ck-message .ant-message-notice-content {
  padding: 0 !important;
  overflow: hidden; 
  border-radius: 10px !important;
  width: auto;
  max-width: 90%;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(68,68,68,0.15);
  -moz-box-shadow: 0px 2px 8px 0px rgba(68,68,68,0.15);
  box-shadow: 0px 2px 8px 0px rgba(68,68,68,0.15);
}
.ck-message .ant-message-notice-content .ant-message-custom-content {
  padding: 10px 15px !important;
  background-color: var(--light-blue);
  border: 1px solid #D0E5FF;
  border-radius: 10px !important;
  overflow: hidden; 
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.ck-message .ant-message-notice-content .ant-message-custom-content.ant-message-success {
  background-color: #F6FFED;
  border-color: #CCF4C2;
}
.ck-message .ant-message-notice-content .ant-message-custom-content.ant-message-warning {
  background-color: #FFFBE6;
  border-color: #FFE58F;
}
.ck-message .ant-message-notice-content .ant-message-custom-content.ant-message-error {
  background-color: #FFF1F0;
  border-color: #FFCECE;
}
.ck-message .ant-message-notice-content .ant-message-custom-content p {
  color: #404040;
  margin: 0;
}
.ck-message .ant-message-notice-content .ant-message-custom-content p.title {
  font-size: 20px !important;
  line-height: 28px;
  font-weight: 600;
}
.ck-message .ant-message-notice-content .ant-message-custom-content p.text {
  font-size: 16px !important;
  line-height: 24px;
}
.ck-message .ant-message-notice-content .ant-message-custom-content p.text a {
  color: var(--mariner-color);
  font-weight: 600;
}
.ck-message .ant-message-notice-content .ant-message-custom-content p.text a:hover,
.ck-message .ant-message-notice-content .ant-message-custom-content p.text a:focus {
  color: var(--mariner-dark-color);
}
.ck-message .ant-message-notice-content .ant-message-custom-content .text-wrapper {
  text-align: left;
}
@media (max-width: 992px) {
  .ws-app.authenticated .ck-message.ant-message-notice {
    width: 100% !important;
    margin: 0 !important;
    padding: 10px 15px;
  }
}
.ck-message .ant-message-notice-content .ant-message-custom-content > span:not(.anticon) {
  width: 100%;
}
.ck-message .ant-message-notice-content .ant-message-custom-content .ck-button.ant-btn-icon-only {
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
  margin-top: 5px;
  padding: 0 !important;
}
.ck-message .ant-message-notice-content .ant-message-custom-content .ck-button.ant-btn-icon-only .anticon, 
.ck-message .ant-message-notice-content .ant-message-custom-content .ck-button.ant-btn-icon-only .anticon svg {
  width: 14px;
  height: 14px;
  color: rgba(0, 0, 0, .15);
}
.ck-message .ant-message-notice-content .ant-message-custom-content p {
  margin: 0;
  line-height: 20px;
  font-size: 16px !important;
  color: #444444 !important;
}
.ck-message .ant-message-notice-content .ant-message-custom-content .message-content {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.ck-message.no-icon .ant-message-notice-content .ant-message-custom-content > .anticon {
  display: none;
}
.ck-message .ant-message-notice-content .ant-message-custom-content > .anticon {
  top: 0px;
  margin: 0;
}
.ck-message .ant-message-notice-content .ant-message-custom-content > .anticon, 
.ck-message .ant-message-notice-content .ant-message-custom-content > .anticon svg{
  width: 25px;
  height: 25px;
}
/* @media (max-width: 430px) {
  .ck-message .ant-message-notice-content {
    min-width: 100% !important;
  }
} */
