.ck-switch .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-switch .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-switch label {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
}
.ck-switch .ant-switch {
  background: var(--black-20);
  width: 46px;
  height: 24px;
  transition: all 0.2s ease-in-out;
}
.ck-switch  .ant-switch.ant-switch-checked {
  background: var(--primary-color);
}

.ck-switch .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 4px);
}
.ck-switch .ant-switch-handle {
  top: 3px;
  left: 4px;
}

.ck-switch .ant-switch-loading,
.ck-switch .ant-switch-disabled {
  opacity: 0.5;
}
