/* Variables */
:root {
  /* Animation params */
  --animate-duration: 0.2s;
  --animate-delay: 0.2s;
  --animate-repeat: 1;
  
  /* Colors */
  --primary-color: #001450; /* primary color for all components */
  --primary-color-rgb: 0, 20, 80; /*  primary color rgb  */
  
  --primary-hover-color: #40A9FF; /* primary color for all components */
  --primary-hover-color-rgb: 64, 169, 255; /*  primary color rgb  */

  --primary-active-color: #096DD9; /* primary color for all components */
  --primary-active-color-rgb: 9, 109, 217; /*  primary color rgb  */
  
  --primary-light-color: #E7F2FF; /* primary color for all components */
  --primary-light-color-rgb: 231, 242, 255; /*  primary color rgb  */

  --mariner-color: #2369BD;
  --mariner-color-rgb: 35, 105, 189;

  --mariner-dark-color: #0A4285;
  --mariner-dark-color-rgb: 10, 66, 133;

  --secondary-color: #FE000C;
  --secondary-color-rgb: 254, 0 12;

  --secondary-dark-color: #D5000A;
  --secondary-dark-color-rgb: 213, 0, 10;

  --white: #fff;
  --black: 000;
  --gray-100: #F9F9FA;
  --gray-200: #EDEDED;
  --gray-300: #CCCCCC;
  --gray-350: #D1D1D1;
  --gray-390: #aaa;
  --gray-400: #999999;
  --gray-450: #666666;
  --gray-500: #6D6E71;
  --gray: #404040;
  --gray-700: #282828;

  --CREAT-color: #FEBF1F; /*OLD #ffcc00   */ /* 'Confimarción del cliente pendiente' */
  --PEND-color: #3399ff; /*OLD #ff9900   */ /* 'Confirmación Pendiente' */
  --APPO-color: #3366ff; /*OLD #ff3366   */ /* 'Cita confirmada' */
  --RECEP-color: #ff6699; /*OLD #9900cc   */ /* 'Recepción' */
  --DIAG-color: #cc3399; /*OLD #3333cc   */ /* 'En diagnósito' */
  --QUOT-color: #9966ff; /*OLD #6699ff   */ /* 'Cotización' */
  --PROG-color: #33cc99; /*OLD #339999   */ /* 'Reparando' */
  --READY-color: #33cc00; /*OLD #00cc00   */ /* 'Terminado' */
  --CANCE-color: #FF4D4F; /*OLD #999999   */ /* 'Cancelado' */
  --CLOS-color: #339933; /*OLD #444444   */ /* 'Entregado' */

  --success-color: #339933; /*  success state color */
  --success-bg-color: #CCF4C2; /*  success state color for backgrounds */
  --warning-color: #FEBF1F; /*  warning state color */
  --error-color: #ED1C24; /*  error state color */
  --error-dark-color: #9d0a0e;
  --error-bg-color: #f25a5f;

  --heading-color: rgba(0, 0, 0); /*  heading text color */
  --text-color: #444444; /*  major text color */
  --text-color-secondary: rgba(0, 0, 0, 0.45); /*  secondary text color */
  --disabled-color: rgba(0, 0, 0, 0.25); /*  disable state color */

  --warning-color: #ffaa04;
  --danger-color: #D63B04;
  --low-risk-color: #37D16A;


  /*
    Check this variables
    ========================================================================== */
  --red: #ed1c24; /*  red color */
  --red-rgb: 237, 28, 36; /*  red color rgb */
  --red-dark: #9d0a0e; /*  red color dark */
  --red-dark-rgb: 158, 11, 15; /*  red color dark rgb  */

  --exxon-blue-70: #4c6dbf; /*  Exxon Mobil Blue 70% */
  --exxon-blue-70-rgb: 82, 133, 196; /*  Exxon Mobil Blue 70% rgb  */
  --exxon-blue-60: #6682cb; /*  Exxon Mobil Blue 60% */
  --exxon-blue-60-rgb: 106, 145, 203; /*  Exxon Mobil Blue 60% rgb */

  --blue: #001450; /* primary color for all components */
  --blue-rgb: 0, 20, 80; /*  primary color rgb  */

  --blue-dark: #2249c0; /*   primary tint color */
  --blue-dark-rgb: 34, 73, 192; /* primary tint color rgb */

  --light-blue: #EFF6FF;
  --light-blue-rgb: 234, 239, 254;

  --orange: #f05822; /*  orange color, not expected */
  --orange-rgb: 240, 88, 34; /*  orange color rgb */

  --black-70: #4d4d4d; /*  black 70% */
  --black-70-rgb: 09, 110, 113; /*  black 70% rgb */
  --black-50: #808080; /*  black 50% */ /*  */
  --black-50-rgb: 47, 149, 152; /*  black 50% rgb */
  --black-40: #999999; /*  black 40% */
  --black-40-rgb: 67, 169, 172; /*  black 40% rgb */
  --black-35: #a6a6a6; /*  black 35% */
  --black-35-rgb: 77, 179, 182; /*  black 35% rgb */
  --black-20: #cccccc; /*  black 20% */
  --black-20-rgb: 209, 211, 212; /*  black 20% rgb */
  --black-15: #d9d9d9; /*  black 15% */
  --black-15-rgb: 220, 221, 222; /*  black 15% rgb */
  --black-10: #e6e6e6; /*  black 10% */
  --black-10-rgb: 230, 231, 232; /*  black 10% rgb */
  --black-5: #f2f2f2; /*  black 5% */
  --black-5-rgb: 241, 242, 242; /*  black 5% rgb */
  --black-4: #f5f5f5; /*  black 4% */
  --black-4-rgb: 245, 245, 245; /*  black 4% rgb */


  /* --gray: #eeeeee; */ /*rgba(238,238,238,255)*/

  --footer-background: black;
  --footer-color: white;
  --footer-background-dark: #444444;
  --footer-color-dark: white;

  --whatsapp-color: #075e54;

  --background: #ffffff;

  

  /* Legacy and used variables */
  --zumthor-color: #d1d3d4;
  --gray-light-color: #939598;
  --eucalyptus-light-color: #4bca81;

  --background-contrast-color: #303030;


  /* Font styles */
  --font-size-base: 16px; /*  major text font size */
  --font-size-base-secondary: 14px; /*  check this line */
  --font-size-base-small: 13px; /*  check this line */
  --font-family: "EMPrint", sans-serif;
  
  /* Borers */
  
  --border-radius-base: 10px; /*  major border radius */
  --border-color-base: #d9d9d9; /*  major border color */
  --box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); /*  major shadow for layers */

  
  
  /* end consumer layout */
  --offset-desktop: 115px;
  --offset-mobile: 80px;

  --offset-mobilhub-desktop: 0px;
  --offset-mobilhub-mobile: 0px;

  /* Workshop layout */
  --offset-not-auth-desktop: 165px;
  --offset-not-auth-mobile: 130px;
  --offset-navbar-desktop: 91px;
  --offset-navbar-mobile: 126px;
  
}
/* /Variables */
