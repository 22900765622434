.ck-tab-selector.no-margin {
  margin: 0;
}
.ck-tab-selector .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-tab-selector .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-tab-selector label,
.ck-tab-selector .ant-form-item-extra {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  height: 26px;
}
.ck-tab-selector .ant-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ck-tab-selector .ant-radio-wrapper {
  width: 50%;
  text-align: center;
  color: #aaaaaa;
  border: 0 solid var(--mariner-color);
  font-size: 16px;
  font-weight: 600;
  border-bottom-width: 2px;
  height: 36px;
  line-height: 36px;
  margin: 0;
  place-content: center;
}
.ck-tab-selector .ant-radio-wrapper:first-child {
    border-radius: 0 10px 0 0;
}
.ck-tab-selector .ant-radio-wrapper:last-child {
    border-radius: 10px 0 0 0;
}
.ck-tab-selector .ant-radio {
  display: none;
}
.ck-tab-selector .ant-radio-wrapper.ant-radio-wrapper-checked {
  color: var(--primary-color);
  border-bottom-color: transparent;
  border-top-width: 2px;
}
.ck-tab-selector .ant-radio-wrapper:first-child.ant-radio-wrapper-checked {
    border-right-width: 2px;
}
.ck-tab-selector .ant-radio-wrapper:last-child.ant-radio-wrapper-checked {
    border-left-width: 2px;
}
.ck-tab-selector .ant-radio-wrapper:last-child.ant-radio-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 0px transparent;
}

