.ck-select input {
  width: 100%;
}
.ck-select .ant-select-selection-item {
  color: var(--gray);
  font-size: 16px;
}
.ck-select .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-select .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-select .ant-form-item-explain div {
  color: #666;
  font-size: 12px;
  line-height: 15px;
  color: var(--gray-450);
}
.ck-select .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin-right: 0;
  color: var(--text-color);
}
/* ==========================================================================
  Transform options
  ========================================================================== */
.ck-select-popover.lowercase .ant-select-item-option-content {
  text-transform: lowercase;
}
.ck-select-popover.uppercase .ant-select-item-option-content {
  text-transform: uppercase;
}
.ck-select-popover.capitalize .ant-select-item-option-content {
  text-transform: capitalize;
}


/* ==========================================================================
  Default
  ========================================================================== */
.ck-select.default {

}
.ck-select.default .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.ck-select.default .ant-form-item-row .ant-col {
  width: 100%;
  text-align: left;
}
.ck-select.default label,
.ck-select.default .ant-form-item-extra {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 26px;
  height: 26px;
}
.ck-select.default .ant-select-selector {
  border: 0;
  border-bottom: 1px solid var(--text-color);
  border-radius: 0px !important;
}
.ck-select.default .ant-select-arrow {
  color: var(--text-color);
}
.ck-select.default .ant-select-selection-placeholder {
  text-align: left !important;
  color: var(--gray-300) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; 
  padding: 4px 0;
}
.ck-select.default .ant-select-selector {
  padding-left: 0;
  padding-right: 0;
}

.ck-select-popover.default {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #888;
  background: var(--white);
  top: 30px !important;
  font-size: 16px;
  font-weight: 600;
}
.ck-select-popover.default .ant-select-item {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
}
.ck-select-popover.default .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--light-blue);
}



/* ==========================================================================
  Rounded
  ========================================================================== */
.ck-select.rounded {
  color: #fff;
}
.ck-select.rounded label,
.ck-select.rounded .ant-form-item-extra {
  color: #fff;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 26px;
  height: 26px;
}
.ck-select.rounded input {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: var(--white);
  text-align: left;
}

.ck-select.rounded .ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 8px;
  overflow: hidden;
}
.ck-select.rounded .ant-input-clear-icon,
.ck-select.rounded .ant-input-clear-icon svg {
  height: 18px;
  width: auto;
}
.ck-select.rounded .ant-select-selector {
  height: 44px;
}

.ck-select.rounded .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ck-select.rounded .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 44px;
}

.ck-select-popover.rounded {
  border-radius: 8px;
  border: 1px solid #888;
  background: var(--white);
  /* top: 25px !important; */
  font-size: 16px;
  font-weight: 600;
}
.ck-select-popover.rounded .ant-select-item {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
}
.ck-select-popover.rounded .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--light-blue);
}
.ck-select.rounded .ant-form-item-explain div,
.ck-select.rounded .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #fff;
}
